<template>
  <div class="content-wrapper">
    <PageHeader screen-name="Registro de Vacinação" :link-items="filteredLinkItems"/>
    <main class="card" id="select-empresa-componente">
      <overlay :show="loading" class-name="w-100">
        <div v-if="campaignIsNotSelected">
          <VaccinationRecordPlaceholder @selectCampanha="selectCampanha"
                                        @setLoading="setLoading"
          />
        </div>
        <div v-else>
          <RegionalVaccinationRecord v-if="isCampaignRegionalBase"
                                     :campanha="campaignRegional"
                                     @clearSearch="clearSearch"
                                     @setLoading="setLoading"
                                     @selectCampanha="selectCampanha"
          />
          <NationalVaccinationRecord v-if="isCampaignNationalBase"
                                     :campanha="params.campaign"
                                     @clearSearch="clearSearch"
                                     @setLoading="setLoading"
                                     @selectCampanha="selectCampanha"
          />
        </div>
      </overlay>
    </main>
  </div>
</template>

<script>
import {
  BCol, BRow, BFormGroup, BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import RegionalVaccinationRecord from "@/views/components/custom/empresa/RegionalVaccinationRecord.vue";
import NationalVaccinationRecord from "@/views/components/custom/empresa/NationalVaccinationRecord.vue";
import VaccinationRecordPlaceholder from "@/views/components/custom/empresa/VaccinationRecordPlaceholder.vue";
import {tipoCampanhaEnum} from "@/enum/tipoCampanha";
import Overlay from "@/views/components/custom/overlay/Overlay.vue";

export default {
  title: 'Selecionar empresa',

  components: {
    Overlay,
    NationalVaccinationRecord,
    RegionalVaccinationRecord,
    VaccinationRecordPlaceholder,
    BCol,
    BRow,
    BFormGroup,
    BButton,
    vSelect,
    PageHeader,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Vacinação',
          routeName: 'vacinacao-list',
        },
        {
          name: 'Registro de vacinação',
          active: true,
        },
      ],
      loading: false,
      isSuporte: false,
      params: {
        unidade: null,
        campaign: null,
      }
    }
  },
  mounted() {
    this.isSuporte = this.$store.state.sessions.userData.perfis[0].chave === 'SUPORTE';
  },
  methods: {
    setLoading(show = true) {
      this.loading = show
    },
    formatCampaign(campaign) {
      return {
        idCampaign: campaign.id_campanha,
        description: campaign.campanha_descricao,
        tipo: campaign.tipo,
        departmentUf: campaign.departamento_uf
      };
    },
    selectCampanha({campaign}) {
      const loading = campaign?.tipo !== this.params?.campaign?.tipo;

      if (campaign && 'id_campanha' in campaign && campaign?.tipo === 'Nacional') {
        this.params.campaign = this.formatCampaign(campaign);
      } else {
        this.params.campaign = campaign
      }

      this.setLoading(loading)
    },
    clearSearch() {
      this.setLoading(true)
      this.params.campaign = null
      this.$store.commit('vacinacaoState/CLEAR_SEARCH')
      this.setLoading(false)
    }
  },
  computed: {
    isCampaignRegionalBase() {
      return this.params.campaign.tipo === tipoCampanhaEnum.REGIONAL;
    },
    isCampaignNationalBase() {
      return this.params.campaign.tipo === tipoCampanhaEnum.NACIONAL;
    },
    campaignIsNotSelected() {
      return this.params.campaign === null || this.params.campaign === '';
    },
    campaignRegional() {
      return {
        id_campanha: this.params.campaign.idCampaign,
        campanha_descricao: this.params.campaign.description,
        tipo: this.params.campaign.tipo,
        departamento_uf: this.params.campaign.departmentUf
      };
    },
    filteredLinkItems() {
      return !this.isSuporte
        ? this.linkItems
        : this.linkItems.filter(item => item.name !== 'Vacinação');
    }
  }
}
</script>

<style lang="scss">
#select-empresa-componente {
  .font_size_label {
    font-size: 15px;
    margin-top: -5px;
  }

  .custom {
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
  }

  .mono {
    background-color: #f3eeff;
    color: #8c65e2;
  }

  .multi {
    background-color: #e3f6f4;
    color: #21a696;
  }

  .cor_botao {
    color: #2772C0 !important;
    border: 1px solid #2772C0 !important;
  }

  .altura_max_scrollbar {
    max-height: 100px;
    padding-right: 15px;
  }

  .comprimento_maximo_celulas {
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
  }

  .hidden_overflow_tabela {
    overflow: hidden;
  }
}
</style>