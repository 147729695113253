<template>
  <b-row class="pt-2 px-2">
    <b-col
        lg="4"
        md="4"
        sm="12"
    >
      <b-form-group
          label="Campanha"
          label-for="campanha-input"
          label-class="font_size_label"
      >
        <validation-provider
            #default="{ errors }"
            name="campanha"
            rules="required"
        >
          <v-select
              id="select-campanha"
              v-model="params.campaign"
              variant="custom"
              label="description"
              placeholder="Selecione uma campanha"
              :values="campaignSelect"
              :options="campaignSelect"
              @input="setCampaignType"
          >
            <span slot="no-options">Nenhuma opção selecionável.</span>
          </v-select>
          <small
              v-if="errors[0]"
              class="text-danger"
          >
            Este campo é de preenchimento obrigatório.
          </small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col
        lg="4"
        md="4"
        sm="12"
    >
      <b-form-group
          label="Empresa"
          label-for="empresa-input"
          label-class="font_size_label"
      >
        <validation-provider
            #default="{ errors }"
            name="empresa"
            rules="required"
        >
          <v-select
              id="select-empresa"
              variant="custom"
              item-text="descricao"
              item-value="id_empresa"
              label="nome_empresa"
              placeholder="Selecione uma campanha"
              :disabled="true"
          >

            <span slot="no-options">Nenhuma opção selecionável.</span>
          </v-select>
          <small
              v-if="errors[0]"
              class="text-danger"
          >
            Este campo é de preenchimento obrigatório.
          </small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col
        lg="4"
        md="4"
        sm="12"
    >
      <b-form-group
          label="Unidade Operacional"
          label-for="unidade-operacional-input"
          label-class="font_size_label"
      >
        <validation-provider
            #default="{ errors }"
            name="unidade"
            rules="required"
        >
          <v-select
              id="unidade-operacional-input"
              v-model="params.unit"
              :options="unitSelect"
              label="description"
              placeholder="Selecione uma campanha"
              :clearable="true"
              :disabled="true"
          >
            <span slot="no-options">Nenhuma opção selecionável.</span>
          </v-select>
          <small
              v-if="errors[0]"
              class="text-danger"
          >
            Este campo é de preenchimento obrigatório.
          </small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {BCol, BFormGroup, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import {ValidationProvider} from "vee-validate";
import {actions, subjects} from "@/libs/acl/rules";
import chavePerfil from '@/enum/chavePerfil'

export default {
  components: {BRow, ValidationProvider, vSelect, BCol, BFormGroup},

  mounted() {
    this.loadUnits();
    this.loadCampaigns();

    this.$emit('setLoading', false);
  },

  data() {
    return {
      params: {
        unit: null,
        campaign: null
      },
      unitSelect: [],
      campaignSelect: [],
      currentCampaignType: null,
    }
  },

  methods: {
    async loadUnits() {
      const parameters = {
        ativo: true,
      }
      await this.$http.get(this.$api.unidade(), { params: parameters }).then(({ data }) => {
        this.unitSelect = data.map(unit => ({
          idUnit: unit.id_unidade,
          description: unit.descricao_unidade
        }))
      })
    },
    async loadCampaigns() {
      const parameters = {
        situacao: ['Concluída', 'Em andamento'],
      };

      const perfil = this.$store.state.sessions.userData.perfis[0].chave;
      const isVaccinator = perfil === chavePerfil.VACINADOR_PADRAO || perfil === chavePerfil.VACINADOR_ADMIN;
      const isSuporte = perfil === chavePerfil.SUPORTE

      if (!this.$can(actions.INSERIR, subjects.TIPO_ATUACAO_EMPRESA_BASE_NACIONAL) && !isVaccinator && !isSuporte) {
        parameters['tipoCampanha'] = 'Regional'
      }

      await this.$http.get(this.$api.campanha(), {params: parameters}).then(({data}) => {
        this.campaignSelect = data.map(campaign => ({
          idCampaign: campaign.id_campanha,
          description: campaign.campanha_descricao,
          tipo: campaign.tipo,
          departmentUf: campaign.departamento_uf
        }));
      })
    },
    setCampaignType() {
      this.$emit('selectCampanha', {
        campaign: this.params.campaign,
      })
    }
  }
}
</script>